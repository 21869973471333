/* eslint-disable max-len */
// @flow

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const MAKO_ANALYTICS_PAGE_IDS = {
	MAIN: 'HP',
};

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
};

export const PLACEHOLDERS_TEXTS = {
	fullName: 'שם מלא',
	age: 'גיל',
	sportType: 'ענף ספורט',
	phone: 'טלפון',
	email: 'כתובת מייל',
	message: '<span>[עד 120 מילים]</span>',
};

export const ERRORS_TEXTS = {
	fullName: 'יש למלא שם מלא',
	age: 'יש למלא גיל',
	// ageNotANumber: 'גיל חייב להיות מספר בלבד',
	sportType: 'יש למלא את ענף הספורט',
	email: 'יש למלא כתובת מייל',
	emailNotValid: 'יש למלא כתובת מייל תקינה',
	phoneNumber: 'יש למלא מספר טלפון',
	phoneNumberNotValid: 'יש למלא מספר טלפון תקין',
	recaptchaError: `<span>עלייך לאשר שאינך רובוט</span>`,
	message: 'יש למלא מה המטרה שלכם ומה יעזור לכם להיות אלופים',
	messageMaxLength: 'יש למלא מה המטרה שלכם ומה יעזור לכם להיות אלופים עד 120 מילים',
	checkbox: 'יש לאשר את התקנון, מדיניות הפרטיות ותנאי השימוש',
};

export const texts = {
	introTitle: 'ספורטאיות וספורטאים\n – חולמים להצליח בענף הספורט שלכם?',
	introTitleMobile: 'ספורטאיות וספורטאים\n חולמים להצליח בענף הספורט שלכם?',
	introDescription: `במסגרת פרויקט "בוקר טוב יותר" המשותף לתלמה,\n "ידיעות אחרונות" <span><i>ו</i>-ynet</span>,\n אתם מוזמנים לספר מהי המטרה שלכם ואיזה ציוד\n ספורט חסר לכם ויכול לעזור לכם להיות אלופים\n ולמצות את יכולותיכם  -\n ואולי נגשים לכם את החלום ותזכו בשובר לקניית\n ציוד ספורט בשווי ₪3,300`,
};
