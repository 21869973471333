// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';

import { LEAD_CONSTANTS, CAN_USE_DOM } from 'common/constants';
import Icon from 'common/components/Icon';

import { PLACEHOLDERS_TEXTS, COLLECTIONS, ERRORS_TEXTS } from 'client/constants';
import { Form, TextField, Checkbox, Button } from 'client/components/common';
import { initialize, SubmissionError } from 'redux-form';
import { createItem } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';
import terms from 'assets/client/terms.pdf';
import ErrorsPopup from '../Popups/ErrorsPopup';
import SendedFormPopup from '../Popups/SendedFormPopup';
import AlreadyInSystemPopup from '../Popups/AlreadyInSystemPopup';
import ClosedActivityPopup from '../Popups/ClosedActivityPopup';
import * as css from './ContentForm.scss';

type FormData = {
	fullName?: string,
	age?: string,
	phoneNumber?: string,
	email?: string,
	sportType?: string,
	message?: string,
	checkbox?: boolean,
};

type Props = {
	values: FormData,
	errors: FormData,
	createItem: typeof createItem,
};

type State = {
	isUserNotified: boolean,
	isShowSenededFormPopup: boolean,
	isShowAlreadyInSystemPopup: boolean,
	isClosedActivity: boolean,
	isLoading: boolean,
};

const maxWordLength = (value: string, maxWords: number) => {
	if (value === undefined) return maxWords + 1 < 0;

	return value.toString().split(' ').length > maxWords + 1;
};

const FORM_CONFIG = {
	form: 'userInfo',
	// validate: values => {
	// 	const errors = {};
	// 	if (!values.initiativeName) {
	// 		errors.initiativeName = `שם היוזמה – <span>נדרש למלא את שם היוזמה</span>`;
	// 	}
	// 	return errors;
	// },
};

class ContentForm extends React.PureComponent<Props, State> {
	static defaultProps = {
		// className: '',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isUserNotified: false,
			isShowSenededFormPopup: false,
			isShowAlreadyInSystemPopup: false,
			isClosedActivity: true,
			isLoading: false,
		};
	}

	initFormValues = async () => {};

	onFormSubmit = async (values: FormData) => {
		this.setState({
			isUserNotified: false,
			isLoading: true,
		});

		const errorsObj = {};
		// eslint-disable-next-line no-useless-escape
		const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
		// eslint-disable-next-line
		const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!values.fullName) {
			errorsObj.fullName = ERRORS_TEXTS.fullName;
		}

		if (!values.age) {
			errorsObj.age = ERRORS_TEXTS.age;
		}

		if (!values.sportType) {
			errorsObj.sportType = ERRORS_TEXTS.sportType;
		}

		if (!values.phoneNumber) {
			errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumber;
		} else if (values.phoneNumber) {
			const validPhone = phoneRegExp.test(values.phoneNumber.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumberNotValid;
			}
		}

		if (!values.email) {
			errorsObj.email = ERRORS_TEXTS.email;
		} else if (values.email) {
			const validEmail = emailRegExp.test(values.email.toLocaleLowerCase());
			if (!validEmail) {
				errorsObj.email = ERRORS_TEXTS.emailNotValid;
			}
		}

		if (!values.message) {
			errorsObj.message = ERRORS_TEXTS.message;
		} else if (values.message && maxWordLength(values.message, 120)) {
			errorsObj.message = ERRORS_TEXTS.messageMaxLength;
		}

		if (!values.checkbox) {
			errorsObj.checkbox = ERRORS_TEXTS.checkbox;
		}

		if (!_.isEmpty(errorsObj)) {
			const errosWithoutCheckboxes = _.omit(errorsObj, 'checkbox');
			if (errorsObj.checkbox) {
				dataLayerEvents.onFormSended('Fail - חסרה הסכמה בצ’קבוקס');
				this.setState({
					isLoading: false,
				});
			} else if (_.size(errosWithoutCheckboxes) > 0) {
				dataLayerEvents.onFormSended('Fail - חסרים פרטים');
				this.setState({
					isLoading: false,
				});
			}

			throw new SubmissionError(errorsObj);
		}

		if (_.isEmpty(errorsObj)) {
			const leadResult = await this.props.createItem<any>({ collection: COLLECTIONS.LEADS, data: values });

			if (leadResult.lead === LEAD_CONSTANTS.FOUNDED) {
				this.setState({
					isShowAlreadyInSystemPopup: true,
					isLoading: false,
				});
				dataLayerEvents.onFormSended(window.location.href);
				localStorage.setItem('ytlm', 'true');
			}

			if (leadResult.lead === LEAD_CONSTANTS.CREATED) {
				this.setState({
					isShowSenededFormPopup: true,
					isLoading: false,
				});
				dataLayerEvents.onFormSended(window.location.href);
				localStorage.setItem('ytlm', 'true');
			}
		}
	};

	closeErrorPopup = () => {
		this.setState({
			isUserNotified: true,
		});
	};

	render() {
		const { errors } = this.props;
		const { isUserNotified, isShowSenededFormPopup, isShowAlreadyInSystemPopup, isClosedActivity } = this.state;

		let macOs;

		if (CAN_USE_DOM) {
			macOs = /(Mac)/i.test(navigator.platform);
		}

		// const wordCount = (value, limit) => {
		// 	if (value === undefined) return 0;
		// 	return value.trim().split(' ').length;
		// };

		return (
			<div className={css.formWrapper}>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField name="fullName" placeholder={PLACEHOLDERS_TEXTS.fullName} />
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField name="age" placeholder={PLACEHOLDERS_TEXTS.age} />
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField name="sportType" placeholder={PLACEHOLDERS_TEXTS.sportType} />
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField name="phoneNumber" placeholder={PLACEHOLDERS_TEXTS.phone} />
							</div>
							<div className={classNames(css.fieldWrapper, css.middleField)}>
								<TextField name="email" placeholder={PLACEHOLDERS_TEXTS.email} />
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={css.rowTitle}>ספרו לנו על המטרה שלכם ומה יעזור לכם להיות אלופים</div>
						<div className={css.fieldWrapper}>
							<TextField
								name="message"
								placeholder={PLACEHOLDERS_TEXTS.message}
								isTextarea
								textareaType={1}
								maxWordsLength={120}
							/>
							{/* <span
								style={{
									fontSize: '1.3em',
									paddingRight: '0.5vw',
									color: wordCount(this.props.values.message) > 120 ? 'red' : '',
								}}
							>
								{wordCount(this.props.values.message)} / 120
							</span> */}
						</div>
					</div>
					<div className={css.formRow}>
						<div className={classNames(css.checkboxWrapper)}>
							<p className={css.info}>*ההשתתפות לילדים בגילאי 6-18</p>
							<Checkbox
								name="checkbox"
								label={
									<>
										יש לאשר את{' '}
										<a href={terms} target="_blank" rel="noreferrer">
											התקנון,
										</a>{' '}
										ו
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
											target="_blank"
											rel="noreferrer"
										>
											תנאי השימוש,
										</a>{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html"
											target="_blank"
											rel="noreferrer"
										>
											מדיניות הפרטיות
										</a>{' '}
									</>
								}
							/>
						</div>
					</div>
					<div className={classNames(css.formRow, css.withButton)}>
						{this.state.isLoading && <Icon type="preloader" className={css.preloader} />}
						<Button
							className={classNames(css.submitBtn, !macOs && css.forWindows)}
							label={this.state.isLoading ? '' : 'שלח'}
							type="submit"
						/>
					</div>
				</Form>

				<ErrorsPopup
					className={!isUserNotified && !_.isEmpty(errors) ? 'show' : ''}
					closePopup={this.closeErrorPopup}
					errors={errors}
				/>
				<AlreadyInSystemPopup className={isShowAlreadyInSystemPopup ? 'show' : ''} />
				<SendedFormPopup className={isShowSenededFormPopup ? 'show' : ''} />
				<ClosedActivityPopup className={isClosedActivity ? 'show' : ''} />
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.submitErrors';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createItem,
};

export default connect(mapState, mapDispatch)(ContentForm);
