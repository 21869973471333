/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import * as css from './TextField.scss';

type Props = {
	input: {|
		name: string,
		onBlur: Function,
		onChange: Function,
		onDragStart: Function,
		onDrop: Function,
		onFocus: Function,
		value?: string | number,
	|},
	meta: {|
		active?: boolean,
		asyncValidating?: boolean,
		autofilled?: boolean,
		dirty?: boolean,
		dispatch?: Function,
		error?: string,
		form?: string,
		initial?: string,
		invalid?: boolean,
		pristine?: boolean,
		submitFailed?: boolean,
		submitting?: boolean,
		touched?: boolean,
		valid?: boolean,
		visited?: boolean,
		warning?: string,
	|},
	inputRef?: { current: ?HTMLInputElement | ?HTMLTextAreaElement } | null,
	className?: string,
	placeholder?: string,
	eventListeners: {|
		onChange?: Function,
		onFocus?: Function,
		onBlur?: Function,
		onKeyDown?: Function,
		onKeyUp?: Function,
	|},
	isTextarea?: boolean,
	textareaType?: number,
	inlineType?: boolean,
	disabled?: boolean,
	clearValue?: boolean,
	maxWordsLength?: number,
};

type State = {|
	isFocused: boolean,
	value?: string | number,
|};

export default class Input extends React.PureComponent<Props, State> {
	static defaultProps = {
		inputRef: null,
		className: '',
		placeholder: '',
		isTextarea: false,
		textareaType: 1,
		inlineType: false,
		disabled: false,
		clearValue: false,
		maxWordsLength: 1500,
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		if (props.input.value !== state.value) {
			return {
				value: props.input.value,
			};
		}

		return null;
	}

	constructor(props: Props) {
		super(props);

		this.state = {
			isFocused: false,
			value: props.input.value,
		};
	}

	onFocus = (e: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ isFocused: true });

		_.invoke(this.props, 'input.onFocus', e);
	};

	onBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ isFocused: false });

		_.invoke(this.props, 'input.onBlur', e);
	};

	onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const { maxWordsLength } = this.props;

		if (value.length === 0) {
			_.invoke(this.props, 'input.onChange', e);
		}
		if (value && maxWordsLength) {
			if (
				value
					.toString()
					.trim()
					.split(/\s+/).length <
				maxWordsLength + 1
			) {
				_.invoke(this.props, 'input.onChange', e);
			}
		}
	};

	render() {
		const {
			className,
			placeholder,
			isTextarea,
			textareaType,
			inlineType,
			disabled,
			clearValue,
			inputRef,
			...rest
		} = this.props;
		const { isFocused } = this.state;

		const value = clearValue ? '' : _.get(rest, 'input.value', '');
		// console.info('value', value);
		// const isErrorShown = (rest.meta.error && rest.meta.submitFailed) || (rest.meta.error && rest.meta.touched);

		return (
			<label
				className={classNames(
					css.textField,
					(isFocused || value) && css.active,
					isFocused && css.focused,
					className
				)}
			>
				{placeholder && (
					<div
						className={classNames(css.placeholder, value && css.hide, isTextarea && css.forTextarea)}
						dangerouslySetInnerHTML={{ __html: placeholder }}
					></div>
				)}
				{isTextarea && (
					<textarea
						onChange={this.onChange}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						{...rest.input}
						value={value}
						ref={inputRef}
						{...rest.eventListeners}
						className={classNames(textareaType === 1 && css.small, textareaType === 2 && css.big)}
						disabled={disabled}
					></textarea>
				)}
				{!isTextarea && (
					<input
						{...rest.input}
						value={value}
						ref={inputRef}
						{...rest.eventListeners}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						className={classNames(inlineType && css.inline)}
						onChange={this.onChange}
					/>
				)}
			</label>
		);
	}
}
