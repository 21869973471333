// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as css from './ClosedActivity.scss';

type Props = { className?: string };

const ClosedActivityPopup = ({ className }: Props) => {
	return (
		<div className={classNames(css.closedActivity, className)}>
			<div className={classNames(css.closedActivityWrapper)}>
				<p>ההרשמה הסתיימה</p>
			</div>
		</div>
	);
};

ClosedActivityPopup.defaultProps = { className: '' };

export default ClosedActivityPopup;
